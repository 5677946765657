import { useTheme } from "@emotion/react";
import { Button, Input, Popover, Radio } from "antd";
import PenalChevron from "components/exploratory/PenalChevron";
import InfoIcon from "components/icons/InfoIcon";
import PremiumIcon from "components/icons/PremiumIcon";
import { PlanModalContext } from "components/provider/PlanModalProvider";
import ExploratoryTooltip from "components/tooltips/ExploratoryTooltip";
import useFilters from "hooks/useFilters";
import useGeoExploratory, { ExploratoryOption } from "hooks/useGeoExploratory";
import useGeoSearch from "hooks/useGeoSearch";
import { useUserData } from "hooks/useUser";
import { AuthModalContext, MapContext } from "lib/context";
import { slugify } from "lib/helpers";
import { getNameForCode } from "lib/helpers/exploratoryHelpers";
import {
  exploratoryGroupMap,
  popularExploratoryValues,
} from "lib/options/exploratory";
import { exploratoryMap } from "lib/options/exploratoryMap";
import capitalize from "lodash/capitalize";
import { useRouter } from "next/router";
import { event } from "nextjs-google-analytics";
import { FC, useContext, useEffect, useState } from "react";
import { Geo } from "types/MapContext";
import { Flex } from "../Flex";
import GeoSearchBar from "./GeoSearchbar";
import ProfileMenu from "./ProfileMenu";
import { popoverContent } from "lib/constants";
import { Datapoints } from "types/cube";
import posthog from "posthog-js";
import { getStateNameByCode } from "lib/helpers/getArea";

interface Props {
  handleExploratoryChange: (val: any) => void;
}

const USE_INPUT_FOR_SEARCH = false;

const MobileMapHeader: FC<Props> = ({ handleExploratoryChange }) => {
  const router = useRouter();
  const theme = useTheme();
  const user = useUserData();

  const { geo, setGeo, reset } = useGeoSearch();
  const { exploratory, selectedFilters, setSelectedFilters } = useFilters();
  const { exploratoryOptions, groupedExploratoriesWithPopularAndScores } =
    useGeoExploratory(geo);

  const { setGeoCount, geoCount } = useContext(MapContext);
  const { setShowPlanModal, setPlanPopupTrigger } =
    useContext(PlanModalContext);
  const { setShowAuthModal, setAuthType } = useContext(AuthModalContext);

  const [mounted, setMounted] = useState<boolean>(false);
  const [openExploratory, setOpenExploratory] = useState(false);
  const [searchExploratoryValue, setSearchExploratoryValue] = useState("");
  const [searchPlaceholder, setSearchPlaceholder] = useState(
    exploratory ? getNameForCode(exploratory) : "Search Data Point",
  );
  const [showMore, setShowMore] = useState(
    groupedExploratoriesWithPopularAndScores.map((group) => ({
      label: group.label,
      value: false,
      display: group.options.length > 3,
    })),
  );

  const dropdownItems = groupedExploratoriesWithPopularAndScores.map((elem) => {
    const element = {
      label: elem.label,
      options: elem.options.filter(
        (elem) =>
          !elem.label.includes("Long-Term Growth") &&
          !elem.label.includes("Investor Forecast"),
      ),
    };
    return element;
  });

  useEffect(() => {
    dataPointHandler(exploratory);
  }, [geo]);

  const dataPointHandler = (data: string) => {
    switch (geo) {
      case Geo.COUNTY:
        if (data === Datapoints.RENT_GROWTH_YOY) {
          handleChangeDataPoint(Datapoints.RENT_FOR_APARTMENTS);
        }
        break;
      case Geo.ZIP:
        if (data === Datapoints.RENT_GROWTH_YOY) {
          handleChangeDataPoint(Datapoints.RENT_FOR_APARTMENTS);
        }
        break;
      default:
        handleChangeDataPoint(data);
        break;
    }
  };

  useEffect(() => {
    const option = exploratoryOptions.find(
      (exp) => exp.value === exploratory,
    ) as ExploratoryOption;

    setSearchPlaceholder(option?.label);
  }, [exploratory]);

  useEffect(() => {
    setMounted(true);
  }, []);

  const handleChangeDataPoint = (value: string) => {
    const option = exploratoryOptions.find(
      (exp) => exp.value === value,
    ) as ExploratoryOption;

    event(`map-control-data-point-${slugify(option.label)}`, {
      category: "Map Control",
      label: `Data Point, ${option.label} (${option.category})`,
    });

    if (option.premium && !user.isPremiumOrBasic) {
      setPlanPopupTrigger(`Change Datapoint: ${value}`);
      setShowPlanModal(true);
      return;
    }

    handleExploratoryChange(option.value);
    setSearchExploratoryValue("");
    setSearchPlaceholder(option.label);
    setOpenExploratory(false);
  };

  const handleSignOut = async () => {
    await user.signOut!();
    setShowAuthModal(true);
    reset();
    handleExploratoryChange(exploratoryMap.home_value.code);
    setSearchPlaceholder(getNameForCode(exploratoryMap.home_value.code));
    router.replace("/dashboard", undefined, { shallow: true });
  };

  const renderRadioOptions = () => {
    return (
      <Radio.Group
        value={geo}
        css={(theme) => ({
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: `calc(100% - ${theme.margin.large}px)`,
        })}
      >
        {(["state", "metro", "county", "zip"] as Geo[]).map((item) => (
          <Radio
            id={`geo-${item}-radio`}
            key={item}
            value={item}
            onChange={() => {
              event(`map-control-${item}`, {
                category: "Map Control",
                label: item,
              });

              posthog.capture("Change Geo", {
                switchedFrom: geo,
                switchedTo: item,
                activeState: getStateNameByCode(user?.activeState),
                platform: "Web",
              });
              setGeo(item);
              setGeoCount(geoCount + 1);
              if (item === "zip") {
                //reset largest geo option and filter by default metro if exists
                const hasLargestGeo = selectedFilters.find(
                  (filterData) =>
                    filterData?.label === "Population" &&
                    (filterData?.val as string[]).includes("vh") &&
                    filterData?.val?.length === 1,
                );

                const updatedFilters = selectedFilters.filter(
                  (filterData) => filterData.label !== "Population",
                );

                if (hasLargestGeo) {
                  setSelectedFilters([
                    ...updatedFilters,
                    {
                      label: "Population",
                      val: ["vl", "l", "m", "h", "vh"],
                    },
                  ]);
                }
              }
            }}
            css={{
              ".ant-radio-checked .ant-radio-inner": {
                border: `4px solid ${theme.colors.primary}`,

                ":after": {
                  backgroundColor: "transparent",
                },
              },
            }}
          >
            {capitalize(item)}
          </Radio>
        ))}
      </Radio.Group>
    );
  };

  const renderExploratoryMenu = () => {
    return (
      <Flex
        direction="column"
        css={(theme) => ({
          maxHeight: openExploratory ? "80vh" : 65,
          width: "100%",
          padding: `0 ${theme.padding.medium}px`,
          borderTop: `1px solid ${theme.colors.inputBorder}`,
          transition: "max-height 0.3s ease",
          overflow: "hidden",
        })}
      >
        {USE_INPUT_FOR_SEARCH ? (
          <Input
            value={searchExploratoryValue}
            placeholder={searchPlaceholder}
            onBlur={() => setOpenExploratory(false)}
            onClick={() => setOpenExploratory((prev) => !prev)}
            onChange={(e) => setSearchExploratoryValue(e.target.value)}
            suffix={<PenalChevron active={openExploratory} />}
            css={{
              minHeight: 41,
              width: `100%`,
              padding: `0 ${theme.padding.default}px`,
              marginTop: theme.margin.mediumSmaller,
              marginBottom: theme.margin.mediumSmaller,
              border: `1px solid rgba(146, 146, 146, 0.31)`,
              borderRadius: theme.radii.small,
            }}
          />
        ) : (
          <Flex
            justify="space-between"
            align="center"
            css={{
              minHeight: 41,
              width: `100%`,
              padding: `0 ${theme.padding.default}px`,
              marginTop: theme.margin.mediumSmaller,
              marginBottom: theme.margin.mediumSmaller,
              border: `1px solid rgba(146, 146, 146, 0.31)`,
              borderRadius: theme.radii.small,
            }}
            id="change-datapoint-dropdown"
            onClick={() => setOpenExploratory((prev) => !prev)}
          >
            <span>{searchPlaceholder}</span>

            <Flex justify="center" align="center">
              <PenalChevron active={openExploratory} />
            </Flex>
          </Flex>
        )}

        <div
          css={{
            width: "100%",
            padding: `${theme.margin.mediumSmaller}px 0 0`,
            overflow: "scroll",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
            "::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {dropdownItems.map((group, index) => {
            if (group.label === "Popular Data") {
              group.options.sort((a, b) => {
                return (
                  popularExploratoryValues.indexOf(a.value) -
                  popularExploratoryValues.indexOf(b.value)
                );
              });
            }

            const show = showMore.find((v) => v.label === group.label);
            const options = show?.value
              ? group.options
              : group.options.slice(0, 3);

            const exploratoryOptions = options.filter((option) => {
              return option.label
                .toLowerCase()
                .includes(searchExploratoryValue.toLowerCase());
            });

            return (
              <div key={group.label}>
                {!!exploratoryOptions.length && (
                  <span
                    css={{
                      padding: `0 ${theme.padding.default}px`,
                      color: theme.colors.text3,
                      fontSize: theme.fontSizes.small,
                      fontWeight: theme.fontWeights.heading,
                      textTransform: "uppercase",
                    }}
                  >
                    {group.label}
                    {group.label === exploratoryGroupMap.scores && (
                      <span
                        css={{
                          color: theme.colors.primary,
                          fontSize: theme.fontSizes.smaller,
                          marginLeft: 6,
                        }}
                      >
                        New
                      </span>
                    )}
                  </span>
                )}
                <Flex direction="column" css={{ marginTop: 5 }}>
                  {exploratoryOptions.map((option) => {
                    return (
                      <Flex
                        key={option.value}
                        id={`${option.value}-datapoint-radio`}
                        align="center"
                        justify="space-between"
                        gap={theme.gap[1]}
                        css={{
                          width: "100%",
                          height: 35,
                          padding: `0 ${theme.padding.default}px`,
                          fontSize: theme.fontSizes.medium,

                          ":active": {
                            backgroundColor: "rgba(0,0,0,0.1)",
                          },
                        }}
                      >
                        <Flex
                          align="center"
                          justify="space-between"
                          onClick={() => {
                            if (
                              option.value === "rent_growth_yoy" &&
                              geo !== "metro"
                            )
                              return;
                            !option.isComingSoon &&
                              handleChangeDataPoint(option.value);
                          }}
                          css={{ width: "100%" }}
                        >
                          {option.value === "rent_growth_yoy" &&
                          geo !== "metro" ? (
                            <Popover
                              content={popoverContent}
                              title="Alert"
                              placement="bottomLeft"
                            >
                              <span css={{ color: "gray" }}>
                                {option.label}
                              </span>
                            </Popover>
                          ) : (
                            <span>{option.label}</span>
                          )}

                          {!user.isPremiumOrBasic && option.premium && (
                            <PremiumIcon />
                          )}
                        </Flex>

                        {option.isComingSoon && (
                          <span
                            css={{
                              color: theme.colors.white,
                              fontSize: theme.fontSizes.smaller,
                              background: theme.colors.erieBlack,
                              borderRadius: theme.radii.large,
                              width: 100,
                              textAlign: "center",
                            }}
                          >
                            Coming Soon
                          </span>
                        )}

                        <div>
                          <ExploratoryTooltip
                            title={option.label}
                            value={option.value}
                            ytVideoCode={option.ytVideoCode}
                            mobileDashboard
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={"15"}
                              height={"14"}
                              viewBox="0 0 15 14"
                              fill="none"
                            >
                              <circle
                                cx="7.625"
                                cy="7"
                                r="7"
                                fill={"#ACACAC"}
                                fillOpacity="0.45"
                              />
                              <path
                                d="M7.05912 11V5.32425H8.63326V11H7.05912ZM7.84988 4.59261C7.61586 4.59261 7.41509 4.51501 7.24758 4.35982C7.08253 4.20216 7 4.0137 7 3.79446C7 3.57768 7.08253 3.39169 7.24758 3.23649C7.41509 3.07883 7.61586 3 7.84988 3C8.08391 3 8.28345 3.07883 8.4485 3.23649C8.61601 3.39169 8.69977 3.57768 8.69977 3.79446C8.69977 4.0137 8.61601 4.20216 8.4485 4.35982C8.28345 4.51501 8.08391 4.59261 7.84988 4.59261Z"
                                fill={"#5C5C5C"}
                              />
                            </svg>
                          </ExploratoryTooltip>
                        </div>
                      </Flex>
                    );
                  })}
                  {show?.display && (
                    <Flex justify="center" css={{ width: "100%" }}>
                      <a
                        onClick={() => {
                          setShowMore((prev) => {
                            return prev.map((v) => {
                              if (v.label === group.label) {
                                return {
                                  ...v,
                                  value: !v.value,
                                };
                              }

                              return v;
                            });
                          });
                        }}
                      >
                        show {show?.value ? "less" : "more"}
                      </a>
                    </Flex>
                  )}
                </Flex>

                {!!exploratoryOptions.length && (
                  <div
                    css={{
                      width: "100%",
                      borderBottom:
                        groupedExploratoriesWithPopularAndScores.length >
                        index + 1
                          ? "1px solid rgba(146, 146, 146, 0.31)"
                          : "none",
                      margin: "15px 0",
                    }}
                  />
                )}
              </div>
            );
          })}
        </div>
      </Flex>
    );
  };

  if (!mounted) {
    return null;
  }

  return (
    <>
      <Flex
        direction="column"
        css={(theme) => ({
          position: "absolute",
          backgroundColor: theme.colors.white,
          boxShadow: "0px 4px 22px 0px rgba(0, 0, 0, 0.25)",
          top: theme.margin.large,
          left: theme.margin.medium,
          right: theme.margin.medium,
          zIndex: 260,
        })}
      >
        <Flex
          align="center"
          css={(theme) => ({
            width: `100%`,
            height: 52,
            paddingRight: theme.padding.medium,
          })}
        >
          <div css={(theme) => ({ flex: 1 })}>
            <GeoSearchBar />
          </div>

          <Flex justify="center" align="center">
            <div
              css={(theme) => ({
                height: 26,
                width: 1,
                backgroundColor: theme.colors.inputBorder,
                marginRight: theme.margin.mediumSmaller,
              })}
            />

            {user?.uid ? (
              <ProfileMenu
                photoURL={user?.profilePicture}
                onSignOut={handleSignOut}
              />
            ) : (
              <>
                <Button
                  type="link"
                  onClick={() => {
                    setAuthType("signUp");
                    setShowAuthModal(true);
                  }}
                >
                  Sign up
                </Button>
                <Button
                  onClick={() => {
                    setAuthType("login");
                    setShowAuthModal(true);
                  }}
                >
                  Login
                </Button>
              </>
            )}
          </Flex>
        </Flex>

        {renderExploratoryMenu()}
      </Flex>

      <Flex
        justify="center"
        align="center"
        css={(theme) => ({
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          height: 44,
          zIndex: 250,
          backgroundColor: theme.colors.white,
        })}
      >
        {renderRadioOptions()}
      </Flex>
    </>
  );
};

export default MobileMapHeader;
