// Dependencies
import { Button, Select } from "antd";
import { event } from "nextjs-google-analytics";
import { useContext, useEffect, useMemo, useState } from "react";

// Components
import { PlanModalContext } from "components/provider/PlanModalProvider";
import { Flex } from "../Flex";
import AddFilterButton from "./components/AddFilterButton";
import { AddFiltersModal } from "./components/AddFiltersModal";
import FilterLabel from "./components/FilterLabel";
import FilterRange from "./components/FilterRange";

// Utilities
import useFilters from "hooks/useFilters";
import useGeoSearch from "hooks/useGeoSearch";
import useMapData from "hooks/useMapData";
import useProgressQuery from "hooks/useProgressQuery";
import { useUserData } from "hooks/useUser";
import { slugify } from "lib/helpers";
import { getCodeForLabel } from "lib/helpers/exploratoryHelpers";
import { usePostHog } from "posthog-js/react";
import { IFilter } from "types/options";
import {
  generateMonthsWithLastDayArray,
  generateYearsArray,
  stateCoordinates,
  stateMap,
} from "lib/constants";
import { Geo } from "types/MapContext";
import { getStateNameByCode } from "lib/helpers/getArea";
import { geoSettingsMap } from "lib/mapSettings";

// Component props
interface Props {
  lastFilterAsExploratory: boolean;
}

export function MapFilters({ lastFilterAsExploratory }: Props) {
  const user = useUserData();
  const posthog = usePostHog();
  const {
    geo,
    selectedFeature,
    searchZipsViaState,
    filterYear,
    filterMonth,
    setSearchZipsViaState,
    searchValue,
    setSearchValue,
    setZoom,
    setLat,
    setLong,
    setSearchId,
    setFilterYear,
    setFilterMonth,
  } = useGeoSearch();
  const { currentData } = useMapData();
  const {
    exploratory,
    selectedPreset,
    selectedFilters,
    resetFilters,
    updatePresets,
    setExploratory,
    fetchFilterStates,
    setSelectedFilters,
    setFiltersFromPreset,
  } = useFilters();

  const { setProgressQuery } = useProgressQuery();
  const { setShowPlanModal, setPlanPopupTrigger } =
    useContext(PlanModalContext);

  const [expoFilters, setExpoFilters] = useState<IFilter[]>();
  const [filterExploratory, setFilterExploratory] = useState(exploratory);
  const [showAddFiltersModal, setShowAddFiltersModal] = useState(false);
  const [filterViaState, setFilterViaState] = useState<any>("00");
  const availableZipStates = stateCoordinates.map((item) => item.name);

  const currentDate = new Date();
  const years = generateYearsArray();
  const currentYear = new Date().getFullYear();
  const months = generateMonthsWithLastDayArray();
  const currentMonth = `${String(currentDate.getMonth() + 1).padStart(
    2,
    "0",
  )}-${new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0,
  ).getDate()}`;
  const stateOptions = stateMap
    .filter(
      (item) =>
        availableZipStates.includes(item.stateName) ||
        item.stateName === "All States",
    )
    .map((state) => ({
      label: state.stateName,
      value: state.stateCode,
    }));

  useEffect(() => {
    const defaultState = stateCoordinates.find(
      (item) => item.name === "Kansas",
    );
    if (
      searchZipsViaState === "All States" &&
      geo === Geo.ZIP &&
      defaultState &&
      !searchValue
    ) {
      setZoom(geoSettingsMap.state.defaultZoom);
      setLat(defaultState?.lat);
      setLong(defaultState?.lon);
      setSearchId({
        lat: defaultState?.lat,
        long: defaultState?.lon,
        zoom: geoSettingsMap.state.defaultZoom,
      });
    }
  }, [searchZipsViaState]);

  useEffect(() => {
    if (selectedFilters) {
      setExpoFilters((prev) => {
        const updatedFilters =
          prev?.map((filter) => {
            const selected = selectedFilters.find(
              (item) => item.label === filter.label,
            );
            return selected || filter;
          }) || [];
        const additionalFilters = selectedFilters.filter(
          (item) => !prev?.some((f) => f.label === item.label),
        );
        return [...updatedFilters, ...additionalFilters];
      });
    }
  }, [selectedFilters]);

  useEffect(() => {
    if (currentData?.length) {
      const updatedPresets = updatePresets(currentData);

      if (selectedPreset) {
        const preset = updatedPresets.find(
          (preset) => preset.label === selectedPreset,
        );
        if (preset) {
          setFiltersFromPreset(preset);
        }
      }
    }
  }, [currentData, selectedFeature]);

  const filterState = useMemo(() => {
    return currentData?.length && expoFilters?.length
      ? fetchFilterStates(expoFilters, currentData)
      : {};
  }, [currentData, expoFilters]);

  const canChangeUpdateFilter = (label: string) => {
    return (
      ["Home Value", "Population"].includes(label) || user.isPremiumOrBasic
    );
  };

  const applyFilters = (filters: IFilter[]) => {
    posthog.capture("Apply Filter Click", {
      platform: "Web",
      activeState: getStateNameByCode(user?.activeState),
    });
    setSelectedFilters(filters);
    setProgressQuery((prevState) => ({
      ...prevState,
      filters: JSON.stringify(filters),
    }));
  };
  useEffect(() => {
    if (expoFilters && currentData) {
      const setFiltersExpo = expoFilters;
      let newSelectedFilters = setFiltersExpo.filter(
        (item) => item.val.length > 0,
      );
      if ([Geo.STATE, Geo.ZIP].includes(geo)) {
        newSelectedFilters = newSelectedFilters.filter(
          (item) => item.label !== "state_code",
        );
      }
      setSelectedFilters(newSelectedFilters);
      setExpoFilters(setFiltersExpo);
    }
  }, [currentData]);
  const handleFilterStateChange = (e: any) => {
    const stateName = stateOptions.filter((item) => item.value === e)[0].label;
    setSearchZipsViaState(stateName);
    setSearchValue("");
    setFilterViaState(e);
    if (expoFilters && expoFilters?.length > 0) {
      const filtersToSet = [
        ...expoFilters.filter((filter) => filter.label !== "state_code"),
      ];
      if (e !== "00") {
        filtersToSet.push({
          label: "state_code",
          val: [e.toString()],
        } as any);
      }
      setExpoFilters(filtersToSet);
      applyFilters(filtersToSet);
      setSelectedFilters(filtersToSet);
    }
    setFilterYear("");
    setFilterMonth("");
  };

  const handleYearChange = (e: any) => {
    console.log(e);
    setFilterYear(e);
  };
  const handleMonthChange = (e: any) => {
    console.log(e);
    setFilterMonth(e);
  };

  return (
    <div>
      {geo !== Geo.STATE && (
        <div
          css={{
            width: "auto",
            padding: "10px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Select
            defaultValue={"00"}
            options={stateOptions}
            value={geo === Geo.ZIP ? searchZipsViaState : filterViaState}
            onChange={handleFilterStateChange}
            css={{ width: "100%", minWidth: 100 }}
          ></Select>
        </div>
      )}

      <div
        css={{
          width: "auto",
          padding: "10px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {/*<Select
            placeholder="Select Year"
            options={years}
            value={filterYear ? filterYear: ""}
            onChange={handleYearChange}
            css={{ width: "100%", minWidth: 100, marginRight: "5px"}}
        ></Select>

        <Select
            placeholder="Select Month"
            options={months}
            value={filterMonth ? filterMonth: ""}
            onChange={handleMonthChange}
            css={{ width: "100%", minWidth: 100, marginLeft: "5px" }}
        ></Select>*/}
      </div>
      {expoFilters &&
        expoFilters.length > 0 &&
        filterState &&
        Object.keys(filterState).length > 0 && (
          <Flex
            align="center"
            direction="column"
            css={(theme) => ({
              paddingBottom: theme.padding.medium,
            })}
          >
            {expoFilters
              .filter((filter) => filter.label !== "state_code")
              .map((filter, index) => (
                <div
                  key={`${filter.label}${Date.now()}`}
                  css={(theme) => ({
                    fontSize: theme.fontSizes.heading,
                    marginTop: theme.margin.medium,
                    display: filter.isHidden ? "none" : "block",
                    width: `calc(100% - ${theme.padding.large * 2}px)`,
                  })}
                >
                  <FilterLabel
                    exploratory={filterExploratory}
                    setExploratory={setFilterExploratory}
                    filter={filter}
                    lastFilterAsExploratory={lastFilterAsExploratory}
                    index={index}
                    expoFilters={expoFilters}
                    setExpoFilters={setExpoFilters}
                  />
                  {!filter.isPreset && (
                    <FilterRange
                      filter={filter}
                      state={
                        filterState[getCodeForLabel(filter.label) as string]
                      }
                      onBeforeChange={() => {
                        if (!canChangeUpdateFilter(filter.label)) {
                          setPlanPopupTrigger("Update filter");
                          setShowPlanModal(true);
                        }
                      }}
                      onChange={(val) => {
                        if (!canChangeUpdateFilter(filter.label)) {
                          return;
                        }

                        event(`filter-${slugify(filter.label)}`, {
                          category: "Filter",
                          label: filter.label,
                        });

                        const filtersToSet = [...expoFilters];
                        const { minVal, maxVal } =
                          filterState[getCodeForLabel(filter.label)];

                        filtersToSet[index].val =
                          val[0] === minVal && val[1] === maxVal ? [] : val;
                        setExpoFilters(filtersToSet);

                        applyFilters(filtersToSet);
                      }}
                    />
                  )}
                </div>
              ))}
          </Flex>
        )}

      <Flex
        wrap="wrap"
        css={(theme) => ({
          backgroundColor: theme.colors.white,
          position: "sticky",
          bottom: 0,
          gap: theme.gap[4],
          padding: `${theme.gap[4]}px ${theme.padding.large}px ${theme.padding.medium}px`,
          borderTop: `1px solid ${theme.colors.inputBorder}`,
        })}
      >
        <Flex css={{ width: "100%" }}>
          <AddFilterButton
            onClick={() => {
              if (!user.isPremiumOrBasic) {
                setPlanPopupTrigger("Add Filter");
                setShowPlanModal(true);
                return;
              }
              setShowAddFiltersModal(true);
            }}
          />
        </Flex>
        <Flex
          flex="1"
          css={(theme) => ({
            ".ant-btn": {
              width: "100%",
              fontSize: theme.fontSizes.medium,
              borderRadius: theme.radii.small,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
            ".ant-btn-default": {
              borderRadius: 4,
              color: theme.colors.primary,
              borderColor: theme.colors.primary,

              ":hover": {
                color: theme.colors.white,
                backgroundColor: theme.colors.primary,
              },
            },
          })}
        >
          <Button
            onClick={() => {
              handleFilterStateChange("00");
              const defaultFilters = resetFilters();
              setExpoFilters(defaultFilters);
            }}
          >
            Reset
          </Button>
        </Flex>
      </Flex>

      <AddFiltersModal
        geo={geo}
        modalIsOpen={showAddFiltersModal}
        setModalIsOpen={setShowAddFiltersModal}
        lastFilterAsExploratory={lastFilterAsExploratory}
        setExploratory={setExploratory}
        expoFilters={expoFilters}
      />
    </div>
  );
}
